import React from "react";
import { pageViewStyle } from "../../../pageViewStyle";
import { IconMagnifyingGlass } from "../../svgIcons";
import NavDropdown from "../Navigation/NavDropdown/NavDropdown";
import { SearchInput } from "../../GlobalSearch";
export const SearcherWidgets = ({ searchItems, searchPlaceholder }) => {
    const [activeWidget, setActiveWidget] = React.useState(undefined);
    const [data, setData] = React.useState("");
    const searchActiveValue = activeWidget === "search" ? undefined : "search";
    const searchFieldHandler = React.useCallback(e => setData(e.target.value), []);
    const handleOnSearchClick = React.useCallback(() => {
        window.open(`${window.location.origin}/${window.location.href.split("/")[3]}/search-results?searchterms=${data}`, "_self", "noreferrer");
    }, [data]);
    const clickHandler = React.useCallback((e) => {
        const currentView = pageViewStyle.getView();
        const mustTriggerSearch = (currentView === "small" || activeWidget === "search") && e.key === "Enter";
        if (mustTriggerSearch) {
            window.open(`${window.location.origin}/${window.location.href.split("/")[3]}/search-results?searchterms=${data}`, "_self", "noreferrer");
        }
        activeWidget === "search" &&
            e.key === "Enter" && console.warn(data);
    }, [activeWidget, data]);
    const activeWidgetHandler = (val) => React.useCallback((event) => {
        event.preventDefault();
        setActiveWidget(val === activeWidget ? undefined : val);
        const bodyElement = document.body;
        if (val === "search") {
            bodyElement
                .classList.remove("mobile-nav-active");
            bodyElement
                .classList.remove("menu-active");
            bodyElement
                .querySelector(".navDropdown.active")
                ?.classList.remove("active");
            bodyElement
                .classList.add("search-active");
        }
        else {
            bodyElement
                .classList.remove("search-active");
        }
    }, [val]);
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { onClick: activeWidgetHandler(searchActiveValue) }, IconMagnifyingGlass()),
        React.createElement(NavDropdown, { data: activeWidget === "search" ? searchItems : undefined, dropdownType: "widget", click: activeWidgetHandler(searchActiveValue) },
            React.createElement("div", { className: "searchWrapper" },
                React.createElement(SearchInput, { onSearch: clickHandler, onChange: searchFieldHandler, searchPlaceholder: searchPlaceholder, searchValue: data }),
                React.createElement("a", { className: "searchWrapper__searchIcon", onClick: handleOnSearchClick }, IconMagnifyingGlass())))));
};
