import React, { useState, useCallback, useEffect } from "react";
export const RiskWarningRibbon = ({ descriptionHtml, isLanding }) => {
    const [expanded, setExpanded] = useState(true);
    const exp = useCallback(() => {
        setExpanded(e => !e);
    }, []);
    useEffect(() => {
        if (descriptionHtml !== undefined && descriptionHtml !== "" && !isLanding) {
            document.body.classList.add("has-risk-warning");
        }
    }, [descriptionHtml, isLanding]);
    const toRenderOrNot = () => descriptionHtml !== undefined && descriptionHtml !== "";
    return toRenderOrNot() ?
        React.createElement("div", { className: `risk-warning-ribbon centered-container ${isLanding ? "risk-warning-ribbon--landing" : ""}` },
            React.createElement("div", { className: "risk-warning-ribbon__container" },
                React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: `risk-warning-ribbon__content ${expanded ? "risk-warning-ribbon__content-ellipsis" : ""}`, dangerouslySetInnerHTML: {
                            __html: descriptionHtml
                        } }),
                    React.createElement("span", { className: "risk-warning-ribbon__arrow-container hide-large-ndb", onClick: exp },
                        React.createElement("svg", { className: `risk-warning-ribbon__arrow risk-warning-ribbon__arrow-light ${!expanded ? "rotate" : ""}`, xmlns: "http://www.w3.org/2000/svg", width: "12", height: "6", viewBox: "0 0 8 4", fill: "none" },
                            React.createElement("path", { d: "M4 4L6.03983e-07 2.22545e-07L8 -4.76837e-07L4 4Z", fill: "#E7E8EA" })),
                        React.createElement("svg", { className: `risk-warning-ribbon__arrow risk-warning-ribbon__arrow-dark ${!expanded ? "rotate" : ""}`, xmlns: "http://www.w3.org/2000/svg", width: "12", height: "6", viewBox: "0 0 8 4", fill: "none" },
                            React.createElement("path", { d: "M4 4L6.03983e-07 2.22545e-07L8 -4.76837e-07L4 4Z", fill: "#0E1D31" })))))) : React.createElement(React.Fragment, null);
};
