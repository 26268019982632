import React, { useCallback } from "react";
export const SearchInput = ({ onSearch, onChange, searchValue, searchPlaceholder }) => {
    const [inputValue, setInputValue] = React.useState();
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            inputValue && onSearch(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue, onSearch]);
    const handleKeyDown = useCallback((event) => {
        if (event.key === "Enter") {
            setInputValue(event);
        }
    }, []);
    return React.createElement("div", { className: "search-input" },
        React.createElement("input", { className: "search-input__input", onKeyDown: handleKeyDown, onChange: onChange, value: searchValue, type: "search", placeholder: searchPlaceholder, maxLength: 50 }));
};
