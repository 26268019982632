import React from "react";
function useOutsideClick(ref, func) {
    React.useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                func(event);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
export default function ClickWrapper(props) {
    const wrapperRef = React.createRef();
    useOutsideClick(wrapperRef, props.func);
    return React.createElement("div", { className: props.classes, ref: wrapperRef }, props.children);
}
