import React, { useCallback } from "react";
import { IconChevronDown } from "../../../svgIcons";
import { Img } from "../../../Img";
const calcCols = (param, optionalParam) => (optionalParam ? param + 1 : param);
const NavDropdownHover = ({ click, data, children, sectionWrapperRef, }) => {
    const colsLength = calcCols(data?.sections.length, data?.article);
    const navWidget = React.useCallback((item, index, i) => {
        if (typeof item?.icon === "string" && item?.icon?.length > 0) {
            return (React.createElement("a", { href: item.linkUrl, key: `dropdown-section-${i}-card-${index}`, className: "nav-widget" },
                React.createElement(Img, { src: item.icon, className: "nav-widget__icon", alt: item.title }),
                React.createElement("p", { className: "nav-widget__title" }, item.title),
                IconChevronDown({})));
        }
        return undefined;
    }, []);
    const navCardWithLink = React.useCallback((item, index, i) => {
        if (item?.linkUrl?.length) {
            return (React.createElement("a", { key: `dropdown-section-${i}-card-${index}`, href: item.linkUrl, className: "nav-card", target: item.opensInNewTab ? "__blank" : "_self" },
                React.createElement(Img, { className: "navDropdown__image", src: item.imageUrl, alt: item.title }),
                React.createElement("span", { className: "nav-card__textWrapper" },
                    React.createElement("div", { className: "nav-card__title" },
                        item.title,
                        IconChevronDown({ width: 16, height: 16 })),
                    item.description && (React.createElement("span", { className: "nav-card__text" },
                        React.createElement("p", { className: "nav-card__text--desc", dangerouslySetInnerHTML: { __html: item.description } }))))));
        }
        return undefined;
    }, []);
    const navCardWithMoreLink = React.useCallback((item, index, i) => {
        if (item?.linkMoreUrl) {
            return (React.createElement("div", { className: "registerStyleWrapper", key: `dropdown-section-${i}-card-${index}` },
                React.createElement("a", { className: `nav-card ${(item.type === "register" || item.type === "login") ? "registerStyle" : ""}`, href: "#", target: item.opensInNewTab ? "__blank" : "_self" },
                    React.createElement("img", { src: item.imageUrl, alt: item.imageUrl }),
                    React.createElement("div", { className: "titleWrapper" },
                        React.createElement("span", null,
                            React.createElement("p", { className: "nav-card__title" }, item.title),
                            IconChevronDown({ fill: "#0E1D31", width: 15 })),
                        item.description && (React.createElement("p", { className: "nav-card__text" }, item.description))))));
        }
        return undefined;
    }, []);
    const sectionItem = React.useCallback((item, index, i) => {
        const widget = navWidget(item, index, i);
        const cardWithLink = navCardWithLink(item, index, i);
        const cardWithMore = navCardWithMoreLink(item, index, i);
        if (widget) {
            return widget;
        }
        if (cardWithLink) {
            return cardWithLink;
        }
        if (cardWithMore) {
            return cardWithMore;
        }
        return React.createElement(React.Fragment, null);
    }, []);
    const validItem = (section) => {
        if (section.sectionItems[0].linkUrl === "") {
            return false;
        }
        return true;
    };
    const handleValidMenuItems = useCallback(() => {
        let response = false;
        if (data) {
            response = validItem(data?.sections[0]);
            if (!response) {
                return undefined;
            }
            return data.sections.map((section, i) => (React.createElement("div", { className: "navDropdown__section", key: `dropdown-section-${i}` },
                React.createElement("p", { className: "navDropdown__title" }, section.title),
                React.createElement("div", { className: `navDropdown__cards ${section.sectionItems[0].code === "" ? "navDropdown__cards--two-col" : "navDropdown__cards--two-col-coloured"}` }, section.sectionItems && section.sectionItems.map((item, index) => sectionItem(item, index, i))))));
        }
        return undefined;
    }, [data, sectionItem]);
    return (React.createElement("div", { className: `navDropdown navDropdown-hover navDropdown__cols-${colsLength}` },
        React.createElement("div", { className: "navDropdown__content" }, data && (React.createElement("div", { ref: sectionWrapperRef, className: "sectionWrapper" },
            React.createElement("span", { className: "navDropdown__headerWrapper" },
                React.createElement("span", { onClick: click, className: "navDropdown__backIcon" }, IconChevronDown({ fill: "#000", height: 24, width: 24 })),
                React.createElement("h5", { className: "navDropdown__headerWrapper__heading" }, data.title)),
            handleValidMenuItems(),
            children ? children : undefined)))));
};
export default React.memo(NavDropdownHover);
