import React from "react";
import { Img } from "../Img";
export const Logo = (props) => {
    const logo = (url, cls) => React.createElement(Img, { loading: "eager", src: url, alt: props.alt ? props.alt : "ThinkMarkets", className: `${cls} ${props.classes ? props.classes : ""}`, style: props.style ? props.style : {} });
    const lightLogo = logo(props.imageUrl, "logo-light");
    const darkLogo = props.imageUrlReverse && logo(props.imageUrlReverse, "logo-dark");
    const logoWithSource = React.createElement(React.Fragment, null,
        lightLogo,
        darkLogo);
    return (React.createElement("div", { className: `logo-wrapper${props.imageUrlReverse ? "" : " logo-wrapper--single-logo"}` },
        React.createElement("a", { href: props.logoHref }, logoWithSource)));
};
