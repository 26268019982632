import React, { useState } from "react";
import { IconClose, IconHamburger } from "../../svgIcons";
export const MobileTrigger = () => {
    const [active, setActive] = useState(false);
    const activeMobileNavHandler = () => (event) => {
        event.preventDefault();
        if (active) {
            document.getElementsByTagName("body")[0].classList.remove("mobile-nav-active");
        }
        else {
            document.getElementsByTagName("body")[0].classList.add("mobile-nav-active");
        }
        setActive(active);
    };
    return React.createElement("a", { href: "#", className: "navigation__mobile-trigger", onClick: activeMobileNavHandler() }, active ? IconClose() : IconHamburger());
};
