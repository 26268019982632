const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
};
const ERROR_TYPE_MAP = new Map(Object.entries({
    400: "400",
    401: "401",
}));
const getErrorType = (responseCode) => ERROR_TYPE_MAP[responseCode] ?? "An error has occured";
export const fetchSearchResults = async (requestUrl, payloadObj, signal) => {
    const queryParams = Object.entries(payloadObj).reduce((prev, [key, value]) => `${prev}${key}=${value}&`, "");
    if (signal) {
        requestOptions.signal = signal;
    }
    try {
        const response = await fetch(`${requestUrl}?${queryParams}`, requestOptions);
        if (!response.ok) {
            return {
                isError: true,
                errorType: getErrorType(response.status),
                results: undefined
            };
        }
        const results = await response.json();
        return {
            isError: false,
            errorType: "",
            results
        };
    }
    catch (error) {
        console.error("GlobalSearch error:", error);
        let message;
        if (error instanceof Error) {
            if (error.name === "AbortError") {
                return {
                    isError: false,
                    errorType: "",
                    results: undefined
                };
            }
            message = error.message;
        }
        else {
            message = String(error);
        }
        return {
            isError: true,
            errorType: message,
            results: undefined
        };
    }
};
