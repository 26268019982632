import React from "react";
import { Logo } from "../../Logo/Logo";
import { MobileTrigger } from "../MobileTrigger";
import { NavigationWidgets } from "../NavigationWidgets";
import { SearcherWidgets } from "./SearcherWidgets";
export const TabletHeader = ({ widgets, searchWidgetProps, imageUrl, imageUrlReverse, logoHref, searchPlaceholder }) => (React.createElement("div", { className: "g-tablet-header__navigation" },
    React.createElement(Logo, { logoHref: logoHref ? logoHref : "/", imageUrl: imageUrl ? imageUrl : undefined, imageUrlReverse: imageUrlReverse ? imageUrlReverse : undefined }),
    React.createElement("div", { className: "optionsWrapper" },
        React.createElement(SearcherWidgets, { searchItems: searchWidgetProps.searchItems, searchPlaceholder: searchPlaceholder }),
        React.createElement(NavigationWidgets, { language: widgets.language }),
        React.createElement(MobileTrigger, null))));
