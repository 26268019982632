import React from "react";
import { Container, ContainerFW } from "../../global";
import { MobileHeader } from "../viewHeaders/MobileHeader";
import { TabletHeader } from "../viewHeaders/TabletHeader";
import { DesktopHeader } from "../viewHeaders/DesktopHeader";
import { RiskWarningRibbon } from "../../RiskWarningRibbon";
export const NavigationHeader = (props) => (React.createElement("div", { className: "g-header" },
    React.createElement("div", { className: "g-header__overlay" }),
    React.createElement(ContainerFW, null,
        (props.riskWarningMessage !== "" && props.riskWarningMessage !== undefined) && React.createElement(RiskWarningRibbon, { descriptionHtml: props.riskWarningMessage }),
        React.createElement(Container, null,
            React.createElement("div", { className: "g-header__navigation" },
                React.createElement(MobileHeader, { imageUrl: props.imgUrl, imageUrlReverse: props.imgUrlReverse, logoHref: props.logoHref }),
                React.createElement(TabletHeader, { imageUrl: props.imgUrl, imageUrlReverse: props.imgUrlReverse, logoHref: props.logoHref, widgets: props.widgets, searchWidgetProps: props.searchWidgetProps, searchPlaceholder: props.searchPlaceholder }),
                React.createElement(DesktopHeader, { imageUrl: props.imgUrl, imageUrlReverse: props.imgUrlReverse, navigationItems: props.navigationItems, secondaryNavigation: props.secondaryNavigation, widgets: props.widgets, menuTitle: props.menuTitle, searchWidgetProps: props.searchWidgetProps, logoHref: props.logoHref, searchPlaceholder: props.searchPlaceholder }))))));
