import React, { useMemo } from "react";
import NavDropdown from "../Navigation/NavDropdown/NavDropdown";
import ClickWrapper from "../ClickWrapper";
import { IconClose, IconGlobe } from "../../svgIcons";
import { pageViewStyle } from "../../../pageViewStyle";
export const NavigationWidgets = ({ language, click }) => {
    const [activeWidget, setActiveWidget] = React.useState(undefined);
    const [data, setData] = React.useState("");
    const searchActiveValue = activeWidget === "language" ? undefined : "language";
    const outsideClickHandler = React.useCallback(() => {
        setActiveWidget(undefined);
    }, []);
    const searchFieldHandler = React.useCallback(e => setData(e.target.value), [data]);
    const clickHandler = React.useCallback((e) => {
        const currentView = pageViewStyle.getView();
        const mustTriggerSearch = (currentView === "small" || activeWidget === "search") && e.key === "Enter";
        if (mustTriggerSearch) {
            window.open(`${window.location.origin}/${window.location.href.split("/")[3]}/search-results?searchterms=${data}`, "_self", "noreferrer");
        }
        activeWidget === "search" &&
            e.key === "Enter" && console.warn(data);
    }, [activeWidget, data]);
    const activeWidgetHandler = (val) => React.useCallback((event) => {
        event.preventDefault();
        setActiveWidget(val === activeWidget ? undefined : val);
        if (val === "search") {
            document
                .getElementsByTagName("body")[0]
                .classList.add("search-active");
        }
        else {
            document
                .getElementsByTagName("body")[0]
                .classList.remove("search-active");
            if (val === "language") {
                if (document.querySelector(".navDropdown__menuLink.active") ||
                    document.querySelector(".navDropdown__button.active")) {
                    document
                        .querySelector(".navDropdown.active")
                        ?.classList.remove("active");
                    document
                        .querySelector(".navigation__item--current")
                        ?.classList.remove("navigation__item--current");
                    document
                        .getElementsByTagName("body")[0]
                        .classList.remove("menu-active");
                }
                document
                    .querySelector(".navDropdown.active")
                    ?.classList.remove("active");
                document
                    .querySelector(".navigation__item--current")
                    ?.classList.remove("navigation__item--current");
            }
            else {
                document
                    .getElementsByTagName("body")[0]
                    .classList.remove("menu-active");
            }
        }
    }, [val]);
    const handleCloseDropDown = React.useCallback((e) => {
        click && click(e);
        document
            .getElementsByTagName("body")[0]
            .classList.remove("mobile-nav-active");
    }, []);
    const iconElement = useMemo(() => {
        if (language.currentIcon && !/english/i.test(language.currentIcon)) {
            return React.createElement("img", { alt: language.currentCulture, src: language.currentIcon, width: "16px", height: "16px" });
        }
        else {
            return IconGlobe();
        }
    }, [language.currentIcon, language.currentCulture]);
    return (React.createElement(ClickWrapper, { func: outsideClickHandler },
        React.createElement("div", { className: `navigation__widgets ${activeWidget ? `${activeWidget}--active` : ""} ` },
            React.createElement("div", { className: "navigation__widgets__inputWrapper" },
                React.createElement("input", { className: `navigation__search ${activeWidget === "search" ? "active" : ""}`, type: "search", placeholder: "Search site", onKeyDown: clickHandler, onChange: searchFieldHandler, value: data }),
                React.createElement("a", { onClick: handleCloseDropDown }, IconClose()))),
        React.createElement("p", { className: "navigation__widget navigation__widget--lang languageText", onClick: activeWidgetHandler(searchActiveValue) },
            iconElement,
            language.currentCulture),
        React.createElement(NavDropdown, { data: activeWidget === "language" ? language : undefined, dropdownType: "widget", click: activeWidgetHandler(searchActiveValue) })));
};
