import * as React from "react";
import NavDropdown from "../Navigation/NavDropdown/NavDropdown";
import { NavigationWidgets } from "../NavigationWidgets";
export const SecondaryNavigation = (props) => {
    const [dropdownData, setDropdownData] = React.useState(undefined);
    const dropdownHandler = (data) => React.useCallback((e) => {
        e.preventDefault();
        if (document.querySelector(".navDropdown__menuLink.active") ||
            document.querySelector(".navDropdown__button.active") ||
            document.querySelector(".navDropdown__widget.active")) {
            document
                .querySelector(".navDropdown.active")
                ?.classList.remove("active");
            document
                .querySelector(".navigation__item--current")
                ?.classList.remove("navigation__item--current");
            document
                .getElementsByTagName("body")[0]
                .classList.remove("menu-active");
        }
        if (data) {
            document
                .getElementsByTagName("body")[0]
                .classList.add("menu-active");
        }
        else {
            document
                .getElementsByTagName("body")[0]
                .classList.remove("menu-active");
        }
        setDropdownData(data);
    }, [data]);
    const itemWithDropdown = (item) => (React.createElement(React.Fragment, null,
        React.createElement("a", { href: "#", onClick: dropdownHandler(item === dropdownData ? undefined : item) }, item.title),
        React.createElement(NavDropdown, { click: dropdownHandler(undefined), dropdownType: "widget", data: dropdownData })));
    return (React.createElement("div", { className: "secondary-navigation__container" },
        React.createElement("div", { className: "secondary-navigation__items" },
            props.data.map((item, i) => (React.createElement("div", { className: "secondary-navigation__item", key: `secondary-menu__item--${i}` }, item.sections.length > 0 ? itemWithDropdown(item) : (React.createElement("a", { href: item.href, className: "" }, item.title))))),
            React.createElement(NavigationWidgets, { language: props.widgets.language, click: props.click }))));
};
